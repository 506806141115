// Body
$body-bg: #f8fafc;
$body-quiz-bg: #feeee6;

$primary-color: #E76F51;
$primary-light-color: #FDD0A6;
$primary-blue-color: #009cff;
$blue: #2A9D8F;
$dark-blue: #003049;
$text-grey: #667080;
$brand-green: #d2db4f;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$nav-height: 111px;
